<template>
	<div class="container">
		<div class="error404">
			404 page not found
			<p>{{path}}</p>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	@import url('https://fonts.googleapis.com/css?family=Raleway');
	.error404 {
		font-family: 'Raleway', sans-serif;
		font-size: 10rem;
		text-align: center;
		margin-top: 20%;
		color: darkmagenta;
		text-shadow: 0 0 12px rgba(139, 0, 139, .3);
		p {
			font-size: 2rem;
		}
	}
</style>

<script>
	export default {
		data() {
			return {
				path: null
			}
		},
		created() {
			this.path = this.$route.params.any;
		}
	}
</script>