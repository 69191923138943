<template>
    <div>
        <legend>ユーザ一覧</legend>


        <!-- 新規追加ボタン -->
        <div class="text-right mb-2">
            <button class="btn btn-outline-primary" @click="append()">
                新規追加...
            </button>
            <button class="btn btn-outline-primary" @click="sync()">
                ADスキャン...
            </button>
        </div>

        <div class="container-fluid">
            <!-- ユーザテーブル -->
            <table class="table table-hover" style="padding-top: 10px;">
                <thead>
                    <tr>
                        <th>編集</th>
                        <th>メールアドレス</th>
                        <th>氏名</th>
                        <th>管理者</th>
                        <th>ロック</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(v, i) in users">
                        <tr v-bind:class="{ deleted: v.is_locked }" v-bind:id="'tr'+v.id">
                            <td>
                                <a class="btn btn-secondary" v-on:click="edit(v.id)">
                                    <span class="fas fa-edit"></span>
                                </a>
                            </td>
                            <td>{{v.email}}</td>
                            <td>
                                <span class="" v-if="v.is_root"></span>
                                {{v.name}}
                            </td>
                            <td><i class=""></i>
                                <span v-if="!v.is_root" class="fas fa-minus"></span>
                                <span v-else class="fas fa-user-shield"></span>
                            </td>
                            <td>
                                <a class="btn btn-warning" @click="toggle(v)" v-if="!v.deleted_at">
                                    <i class="fas fa-trash-alt"></i>
                                </a>
                                <a class="btn btn-outline-success" @click="toggle(v)" v-else>
                                    <i class="fas fa-redo-alt"></i>
                                </a>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <!-- ユーザマスタモーダル -->
            <users-editor
                id="editor"
                :user_id="user_id"
                @save="reload()"></users-editor>
        </div>
    </div>
</template>

<script>
    import usersEditor from './usersEditor.vue';
    import routing from 'mixins/routing';

    export default {
        mixins: [routing],
        components: {
            usersEditor,
        },
        data() {
            return {
                params: {
                    page: this.$route.query.page ? this.$route.query.page : 1,
                    keywords: this.$route.query.keywords ? this.$route.query.keywords : "",
                },
                show: true,
                user_id: null,
                users: [],
            }
        },
        methods: {
            // ページリロード
            reload() {
                this.show = false;
                let params = {
                    page: this.page,
                    keywords: this.keywords,
                };
                axios.get("/master/users/lists", { params: params }).then(res => {
                    this.users = res.data;
                    this.show = true;
                });
            },
            // ページ遷移
            // 新規追加モーダル
            append() {
                this.edit(null);
            },
            // 編集モーダル
            edit(id) {
                this.user_id = id;
                setTimeout(() => $("#editor").modal());
            },
            sync() {
                swal ({
                    title: "AD同期",
                    text: "ユーザ一覧をActive Directoryに同期します。\nよろしいですか？",
                    icon: "info",
                    buttons: ["キャンセル" , "同期"],
                })
                .then((willDelete) => {
                    if (willDelete) {
                        axios.post("/master/users/sync", ).then(res => {
                            this.reload();
                            setTimeout(() => Alert.msg(res.data));
                        }).catch(err => {
                            Alert.err(err.response);
                        });
                    }
                });
            },
            // 削除・復帰
            toggle(v) {
                // 初期値に復帰情報を設定
                let action = "解除";
                let type = "info";
                let mode = false;
                // 削除の場合、削除情報に変更して表示
                if (!v.is_locked) {
                    action = "ロック";
                    type   = "error";
                    mode = true
                }
                swal ({
                    title: `ユーザマスタ${action}`,
                    text: `指定のユーザを${action}します。\nよろしいですか？`,
                    icon: type,
                    buttons: ["キャンセル" , action],
                    dangerMode: mode,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        axios.post("/master/users/toggle", {id: v.id}).then(res => {
                            this.reload();
                            setTimeout(() => Alert.msg(res.data));
                        }).catch(err => {
                            Alert.err(err.response);
                        });
                    }
                });
            },
        },
    mounted() {
        this.reload();
    },
}
</script>