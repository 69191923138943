<template>
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" :id="id">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>商品編集</span>
                    </h4>
                </div>
                <div class="modal-body">
                    <label>ID</label>
                    <div class="form-group">
                        <input type="text" v-model="editor.id" class="form-control" disabled>
                    </div>
                    <label>商品名</label>
                    <div class="form-group">
                        <input type="text" v-model="editor.article_name" class="form-control" placeholder="商品名を入力してください。">
                    </div>
                    <label>商品ブランド</label>
                    <div class="form-group">
                        <v-select v-model="editor.brand" label="label" :options="article_brands" placeholder="ブランドを選択してください。"></v-select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="save(editor)">保存</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            id: {
                default: "articlesEditor",
            },
            order_id: {},
        },
        data() {
            return {
                editor: {},
                article_brands: [],
            }
        },
        methods: {
            // 保存
            save() {
                axios.post("/master/articles/save", this.editor).then(res => {
                    Alert.msg(res.data);
                    $(`#${this.id}`).modal("hide");
                    this.$emit('save');
                }).catch(err => {
                    Alert.err(err.response);
                });
            },
        },
        mounted() {
            // 編集モーダルが開かれた場合
            $(`#${this.id}`).on("show.bs.modal", (e) => {
                // 編集の場合、該当データを取得
                if (this.order_id) {
                    axios.get("/master/articles/edit", { params: {id: this.order_id}}).then(res => {
                        let rc = res.data;
                        if (Object.keys(rc).length) {
                            this.editor = rc;
                        }
                    }).catch(err => {
                        // 該当データが存在しない場合、編集モーダルを非表示
                        Alert.err("該当レコードは存在しません");
                        $(`#${this.id}`).modal("hide");
                        this.$emit('save');
                    });
                } else {
                    this.editor = {};
                }

                // article_brands取得
                axios.get("/tools/documents/masters").then(res => {
                    this.article_brands = res.data.article_brands;
                }).catch(err => {
                    Alert.err(err.response);
                }).finally(() => this.isLoading = false);
            });
        }
    }
</script>