<template>
    <div>
        <legend>タスク</legend>
        <div class="container-fluid">
            <div class="d-flex flex-row pb-3">
                <div class="col btn-group btn-group-toggle data-container='body'" data-toggle="buttons">
                    <template v-for="v in task_menus">
                        <label class="btn btn-outline-dark" :class="{active: params.menu_id == v.id}">
                            <input type="radio" v-model="params.menu_id"  v-bind:value="v.id" @change="change_menu(v.id)">
                            {{v.menu_name}}
                        </label>
                    </template>
                </div>
            </div>

            <div v-if="$store.state.is_root" class="text-right">
                <button class="btn btn-outline-primary" @click="edit()">新規タスク</button>
            </div>

            <div class="row pt-2" v-if="$store.state.is_root">
                <draggable @end="onEnd($event)" tag="tbody" item-key="no" :list="tasks">
                    <template #item="{ element }">
                        <div v-bind:class="{ deleted: element.deleted_at }" class="col-md-3 my-1" style="display: inline-block;">
                            <div class="card h-100 border-secondary hover">
                                <div class="head-hover text-left border-bottom bg-secondary" style="color:white;"
                                v-clipboard:copy="element.body"
                                @click = onCopy(element.title)>
                                    {{element.title}}
                                    <i class="fas fa-copy"></i>
                                </div>
                                <div class="card-body flex-wrap overflow-auto p-2">
                                    <p>{{element.body}}</p>
                                </div>
                                <div v-if="$store.state.is_root" class="card-footer p-2">
                                    <div v-if="element.footer">
                                        <div class="border-light">{{element.footer}}</div>
                                    </div>
                                    <span class="badge badge-pill badge-success">
                                        <i class="text-right fas fa-edit" @click="edit(element.id)">編集</i>
                                    </span>
                                    <span v-if="element.deleted_at" class="badge badge-pill badge-success">
                                        <i class="fas fa-redo-alt" @click="toggle(element)">復帰</i>
                                    </span>
                                    <span v-else class="badge badge-danger">
                                        <i class="fas fa-trash-alt" @click="toggle(element)">削除</i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>
                </draggable>
            </div>

            <tasks-editor
                id="editor"
                :order_id="order_id"
                @save="reload()"
                ref="tasksEditor">
            </tasks-editor>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .card-footer{
        font-size: 0.8rem;
    }
    .card-body {
        font-size: 1rem;
        height: 130px;
    }

    ::-webkit-scrollbar {
    width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #555;
    }
</style>

<script>
    import tasksEditor from './tasksEditor.vue'
    import routing from 'mixins/routing';
    import draggable from 'vuedraggable';

    export default {
        mixins: [routing],
        components: {
            tasksEditor,
            draggable,
        },
        data () {
            return {
                params: {
                    menu_id: this.$route.query.menu_id ? this.$route.query.menu_id : null,
                    page: this.$route.query.page ? this.$route.query.page : 1,
                    is_master: 1,
                },
                order_id: null,
                isloading: false,
                tasks: [],
                task_menus: [],
            }
        },
        methods: {
            reload() {
                this.isLoading = true;
                axios.get("/tools/tasks/lists", {params: this.params}).then(res => {
                    this.tasks = res.data;
                    this.routing();
                }).catch(err => {
                    Alert.err(err.response);
                }).finally(() => this.isLoading = false);
            },
            menu_reload() {
                axios.get("/tools/tasks/menus", {params: this.params}).then(res => {
                    this.task_menus = res.data.card_menus;
                    this.params.menu_id = this.task_menus[0]["id"];
                    this.routing();
                }).catch(err => {
                    Alert.err(err.response);
                }).finally(() => this.isLoading = false);
            },
            edit(id = null) {
                this.order_id = id;
                setTimeout(() => $("#editor").modal());
            },
            toggle(v) {
                // 初期値に復帰情報を設定
                let action = "復帰";
                let type = "info";
                let mode = false;
                // 削除の場合、削除情報に変更して表示
                if (!v.deleted_at) {
                    action = "削除";
                    type   = "warning";
                    mode = true
                }
                swal ({
                    title: `タスクマスタ${action}`,
                    text: `指定のレコードを${action}します。\nよろしいですか？`,
                    icon: type,
                    buttons: ["キャンセル" , action],
                    dangerMode: mode,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        axios.post("/tools/tasks/toggle", {id: v.id}).then(res => {
                            this.reload();
                            Alert.msg(res.data);
                        }).catch(err => {
                            Alert.err(err.response);
                        });
                    }
                });
            },
            change_menu(menu_id) {
                this.params.menu_id = menu_id;
                this.routing();
            },
            onCopy(title) {
                Alert.msg("【" + title + "】をコピーしました");
            },
            onEnd() {
                // order_number書き換え
                this.tasks.map(function(task, length) {
                    task.order_number = length + 1;
                    return {task}
                });
                this.isLoading = true;
                axios.post("/tools/tasks/dragsort", {tasks: this.tasks}).then(res => {
                    this.reload();
                }).catch(err => {
                    Alert.err(err.response);
                }).finally(() => this.isLoading = false);
            },
        },
        mounted() {
            this.menu_reload();
            this.reload();
        },
    }
</script>