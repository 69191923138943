
export default {
	methods: {
		routing(should_reload = false) {
			this.$router.push({ query: this.params });
			if(should_reload) {
				this.reload();
			}
		},
		// 必ずreloadでtableリロードしてね！
		reload() {
			console.error("you should override reload() method.");
		},
		parseBool(str, def = false) {
			if (str === undefined) {
				return def;
			} else if (typeof str === "boolean") {
				return str;
			} else if (str === "true") {
				return true;
			}
			return false;
		},
		// 数値列等のパラメータ型変換処理など
		reformat() {
			return new Promise((resolve, reject) => {resolve()});
		},
		// overrideしてね
		default(key) {
			return "";
		},
		async ___reloadBrand() {
			if (!this.$store.state.brand || this.$store.state.brand.brand_code != this.$route.params.brand) {
				console.log("reload brand object");
				let rc = await axios.get(`/brand/${this.$route.params.brand}`);
				return this.$store.dispatch("reloadBrand", rc.data);
			}
			return true;
		},
	},
	watch: {
		'$route'(to, from) {
			this.___reloadBrand();
			// 原則ここでリロード叩く
			for (let key in this.params) {
				if (to.query[key] !== undefined) {
					if (typeof to.query[key] == "string" && to.query[key].match(/^(true|false)$/i)) {
						this.params[key] = this.parseBool(to.query[key]);
					} else {
						this.params[key] = to.query[key];
					}
				} else {
					this.params[key] = this.default(key);
				}
			}
			this.reformat().then(() => { setTimeout(() => this.reload()) });
		}
	},
	created() {
		this.___reloadBrand();
		this.reformat();
	}
}