<template>
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" :id="id">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <i class="fas fa-pencil-alt"></i>
                        <span>メニュー編集</span>
                    <div class="d-flex flex-row">
                    <div class="col btn-group btn-group-toggle data-container='body'" data-toggle="buttons">
                        <template v-for="v in documents">
                            <label class="btn btn-outline-dark" :class="{active: params.menu_id == v.id}">
                                <input type="radio" v-model="params.menu_id"  v-bind:value="v.id" @change="change_menu(v.id)">
                                {{v.menu_name}}
                            </label>
                        </template>
                    </div>
                </div>
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <label>ファイル名</label>
                    <div class="form-group">
                        <input type="text" v-model="editor.file_name" class="form-control" placeholder="ファイル名を入力してください。">
                    </div>
                    <label>ブランド</label>
                    <div class="form-group">
                        <v-select v-model="editor.article_brand" label="label" multiple :options="article_brands" placeholder="ブランドを選択してください。"></v-select>
                    </div>
                    <label>商品名</label>
                    <div class="form-group">
                        <v-select v-model="editor.article" label="label" multiple :options="articles" placeholder="商品を選択してください。"></v-select>
                    </div>
                    <label>カテゴリ</label>
                    <div class="from-group">
                        <v-select v-model="editor.category" label="label" multiple :options="categories" placeholder="カテゴリを選択してください。"></v-select>
                    </div>
                    <label>URL</label>
                    <div class="from-group">
                        <textarea v-model="editor.url" class="form-control" cols="5" rows="5" charswidth="13" placeholder="urlを入力してください。"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
                    <button type="button" class="btn btn-primary" @click="save(editor)">保存</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            id: {
                default: "editor",
            },
            order_id: {},
        },
        data() {
            return {
                articles: [],
                article_brands: [],
                categories: [],
                documents: [],
                editor: {},
            }
        },
        methods: {
            // 保存
            save() {
                axios.post("/tools/documents/save", this.editor).then(res => {
                    Alert.msg(res.data);
                    $(`#${this.id}`).modal("hide");
                    this.$emit('save');
                }).catch(err => {
                    Alert.err(err.response);
                });
            },
        },
        mounted() {
            // 編集モーダルが開かれた場合
            $(`#${this.id}`).on("show.bs.modal", (e) => {
                // 編集の場合、該当データを取得
                if (this.order_id) {
                    axios.get("/tools/documents/edit", { params: {id: this.order_id}}).then(res => {
                        let rc = res.data;
                        if (Object.keys(rc).length) {
                            this.editor = rc;
                        }
                    }).catch(err => {
                        // 該当データが存在しない場合、編集モーダルを非表示
                        Alert.err("該当レコードは存在しません");
                        $(`#${this.id}`).modal("hide");
                        this.$emit('save');
                    });

                }
                // 新規追加の場合、項目を空にする
                 else {
                    this.editor = {};
                }

                axios.get("/tools/documents/masters").then(res => {
                    this.articles = res.data.articles;
                    this.article_brands = res.data.article_brands;
                    this.categories = res.data.categories;
                }).catch(err => {
                    Alert.err(err.response);
                }).finally(() => this.isLoading = false);
            });
        }
    }
</script>