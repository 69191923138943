<template>
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" id="editor">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 class="modal-title">
                        <span class="glyphicon glyphicon-pencil"></span>
                        <span v-if="editor.id">ショップメニュー編集</span>
                        <span v-else>新規ショップメニュー作成</span>
                    </h4>
                </div>
                <div class="modal-body">
                    <input type="hidden" name="id" v-model="editor.id">
                        <div class="form-group">
                            <label>ショップメニュー名</label>
                            <input type="text" v-model="editor.menu_name" class="form-control" placeholder="メニュー名" v-on:keyup.ctrl.enter="save(editor)">
                        </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">閉じる</button>
                    <button type="button" class="btn btn-primary" v-on:click="save(editor)">保存(Ctrl+Enter)</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            id: {
                required: true
            },
            order_id: {},
        },
        data() {
            return {
                editor: {},
            }
        },
        methods: {
            // 保存
            save(editor) {
                axios.post("/master/shop_menus/save", this.editor).then(res => {
                    Alert.msg(res.data);
                    $("#editor").modal("hide");
                    this.$emit('save');
                }).catch(err => {
                    let reports = [];
                    // エラー項目を取得
                    if (err.response.data.errors) {
                        for(let k in err.response.data.errors) {
                            reports.push(err.response.data.errors[k]);
                        }
                    }
                    // 重複チェックエラーが発生している場合、エラー内容を取得
                    if(reports.length == 0){
                        reports.push("<br>" + err.response.data.message);
                    }
                    Alert.err("登録失敗" + reports.join("\n"));
                });
            },
        },
        mounted() {
            // 編集モーダルが開かれた場合
            $(`#${this.id}`).on("show.bs.modal", (e) => {
                // 編集の場合、該当データを取得
                if (this.order_id) {
                    axios.get("/master/shop_menus/edit", { params: {id: this.order_id}}).then(res => {
                        let rc = res.data;
                        if (Object.keys(rc).length) {
                            this.editor = rc;
                        }
                    }).catch(err => {
                        // 該当データが存在しない場合、編集モーダルを非表示
                        Alert.err("該当レコードは存在しません");
                        $("#editor").modal("hide");
                        this.$emit('save');
                    });
                // 新規追加の場合、項目を空にする
                } else {
                    this.editor = {};
                }
            });
        }

    }
</script>