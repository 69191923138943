 /**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('bootstrap-notify').default;

import { createApp } from 'vue';

const app = createApp({
      created() {
        this.$store.commit("setUserId", parseInt($("#user_id").val()));
        this.$store.commit("setIsRoot", $("#is_root").val());
        this.$store.commit("setIsDebug", $("#is_debug").val());
        this.$store.commit("setAppName", $("#app_name").val());
    }
});

const BigNumber = require('bignumber.js');

import { VueClipboard } from '@soerenmartius/vue3-clipboard'
app.use(VueClipboard);

app.component('pagination', require('components/pagination').default);

import VueSelect from "vue-select";
app.component("vSelect", VueSelect);

import swal from 'sweetalert';
window.swal = swal;

import Alert from './alert';
window.Alert = Alert;

app.config.compilerOptions.whitespace = 'preserve';

import router from "./router";
app.use(router);

import store from "./store";
app.use(store);

app.mount('#app');