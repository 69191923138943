<template>
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" :id="id">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span class="glyphicon glyphicon-pencil"></span>
                        <span>メニュー編集</span>
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <label>メニュー</label>
                    <div class="form-group">
                        <v-select v-model="editor.menu" label="label" :options="task_menus" placeholder="メニューを選択してください。"></v-select>
                    </div>
                    <label>タイトル</label>
                    <div class="form-group">
                        <input type="text" v-model="editor.title" class="form-control" placeholder="タスクのタイトルを入力してください。">
                    </div>
                    <label>内容</label>
                    <div class="form-group">
                        <textarea v-model="editor.body" class="form-control" cols="10" rows="10" charswidth="23" placeholder="タスク内容を入力してください。"></textarea>
                    </div>
                    <label>フッター</label>
                    <div class="from-group">
                        <textarea v-model="editor.footer" class="form-control" cols="5" rows="5" charswidth="13" placeholder="フッターを入力してください。"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
                    <button type="button" class="btn btn-primary" @click="save(editor)">保存</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vSelect from "vue-select";
    import 'vue-select/dist/vue-select.css';

    export default {
        components: {
            vSelect,
        },
        props: {
            id: {
                default: "editor",
            },
            order_id: {},
        },
        data() {
            return {
                editor: {},
                task_menus: [],
            }
        },
        methods: {
            // 保存
            save() {
                axios.post("/tools/tasks/save", this.editor).then(res => {
                    Alert.msg(res.data);
                    $(`#${this.id}`).modal("hide");
                    this.$emit('save');
                }).catch(err => {
                    Alert.err(err.response);
                });
            },
        },
        mounted() {
            // 編集モーダルが開かれた場合
            $(`#${this.id}`).on("show.bs.modal", (e) => {
                // 編集の場合、該当データを取得
                if (this.order_id) {
                    axios.get("/tools/tasks/edit", { params: {id: this.order_id}}).then(res => {
                        let rc = res.data;
                        if (Object.keys(rc).length) {
                            this.editor = rc;
                        }
                    }).catch(err => {
                        // 該当データが存在しない場合、編集モーダルを非表示
                        Alert.err("該当レコードは存在しません");
                        $(`#${this.id}`).modal("hide");
                        this.$emit('save');
                    });

                }
                // 新規追加の場合、項目を空にする
                 else {
                    this.editor = {};
                }

                axios.get("/tools/tasks/menuLists").then(res => {
                    this.task_menus = res.data;
                }).catch(err => {
                    Alert.err(err.response);
                }).finally(() => this.isLoading = false);
            });
        }
    }
</script>