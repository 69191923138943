<template>
	<nav aria-label="ページ" class="text-center">
		<ul class="pagination" v-if="last() > 1 || always">
			<li class="nav-item" :class="{'disabled': prev() <= 0}">
				<a v-on:click="goto(1)" class="nav-link"><span class="fas fa-fast-backward"></span></a>
			</li>
			<li class="nav-item" :class="{'disabled': prev() <= 0}">
				<a v-on:click="goto(prev())" class="nav-link"><span class="fas fa-step-backward"></span></a>
			</li>
			<template v-for="n in range()">
			<li class="nav-item" :class="{'active': n == page()}"><a v-on:click="goto(n)" class="nav-link">{{ n }}</a></li>
			</template>
			<li v-if="showTotal">
				<a><b>{{total}} 件</b></a>
			</li>
			<li class="nav-item" :class="{'disabled': page() >= last()}"><a v-on:click="goto(next())" class="nav-link"><span class="fas fa-step-forward"></span></a></li>
			<li class="nav-item" :class="{'disabled': page() >= last()}">
				<a v-on:click="goto(last())" class="nav-link"><span class="fas fa-fast-forward"></span></a>
			</li>
		</ul>
	</nav>
</template>

<script>
	export default {
		props: {
			current_page: {},
			last_page: {},
			total: {},
			size: {
				default: 13,
				validator(value) {
					if (value >= 7 && value <= 21) {
						if (value % 2 == 1)
							return true;
					}
					return false;
				}
			},
			showTotal: {
				default: false
			},
			always: false,
		},
		methods: {
			page() {
				return this.current_page;
			},
			prev() {
				return this.current_page - 1;
			},
			next() {
				if (this.current_page + 1 > this.last_page)
					return this.last_page
				else
					return this.current_page + 1
			},
			last() {
				return this.last_page;
			},
			all() {
				return this.total;
			},
			goto(n) {
				this.$emit('goto', n);
			},
			range () {
				let size = parseInt(this.size);
				let start = _.max([this.page() - Math.floor(size / 2), 1]);
				let end   = _.min([start + size, this.last() + 1]);
				start     = _.max([end - size, 1]);
				let range = _.range(start, end);
				return range;
			},
		}
	}
</script>