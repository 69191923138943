<template>
    <div>
        <legend>資料ダウンロード</legend>

        <div v-if="this.$store.state.is_root" class="text-right">
            <button class="btn btn-outline-primary" @click="edit()">新規資料</button>
            <button class="btn btn-outline-primary" @click="categoryEdit()">カテゴリマスタ</button>
            <button class="btn btn-outline-primary" @click="articleEdit()">商品マスタ</button>
            <button class="btn btn-outline-primary" @click="articleBrandsEdit()">商品ブランドマスタ</button>
        </div>
        <div class="container">

            <div class="border border-dark mt-2">
                <div>
                    <h2 class="ml-2">ブランド/商品から絞り込み</h2>
                </div>

                <div class="form-check">
                    <template v-for="(v, i) in article_brands">
                        <input type="checkbox" v-model="brand_searches" :value="v.id" @change="brandCheck(v.id)">
                        <label>{{v.brand_name}}全て</label>
                        <div class="pb-3">
                            <template v-for="a in v.articles" class="form-check-inline">
                                <span class="document">
                                    <input type="checkbox" v-model="article_searches" :value="a.id" @change="reload()">
                                    <label>{{a.article_name}}</label>
                                </span>
                            </template>
                        </div>
                    </template>
                </div>

                <div class="form-check">
                    <template v-for="v in articles">
                        <span class="document" v-if="!v.article_brand_id">
                            <input type="checkbox" v-model="article_searches" :value="v.id" @change="reload()">
                            <label>{{v.article_name}}</label>
                        </span>
                    </template>
                </div>

                <h2 class="ml-2 my-2">カテゴリから絞り込み</h2>
                <div class="form-check">
                    <template v-for="v in categories">
                        <span class="document">
                            <input type="checkbox" v-model="category_searches" :value="v.id" @change="reload()">
                            <label>{{v.category_name}}</label>
                        </span>
                    </template>
                </div>

                <div class="text-center my-3">
                    <button type="button" class="btn btn-secondary btn-lg" @click="reset()">
                        <i class="fas fa-times"></i>
                        絞り込みリセット</button>
                </div>
            </div>
        </div>

        <div class="">
            <table class="table table-hover my-3">
                <thead>
                    <tr class="nowrap">
                        <th v-show="this.is_root">編集</th>
                        <th>ファイル名</th>
                        <th>ブランド</th>
                        <th>商品名</th>
                        <th>カテゴリ</th>
                        <th>URL</th>
                        <th v-show="this.is_root" >削除</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="v in documents" :class="{'deleted': v.deleted_at}">
                        <td v-show="is_root">
                            <a class="btn btn-secondary" @click="edit(v.id)">
                                <span class="fas fa-edit"></span>
                            </a>
                        </td>
                        <td>{{v.file_name}}</td>
                        <td class="nowrap">
                            <template v-for="ab in v.article_brands">
                                <div>
                                    {{ab.brand_name}}
                                </div>
                            </template>
                        </td>
                        <td class="nowrap">
                            <template v-for="a in v.articles">
                                <div>
                                    {{a.article_name}}
                                </div>
                            </template>
                        </td>
                        <td class="nowrap">
                            <template v-for="c in v.categories">
                                <div>
                                    {{c.category_name}}
                                </div>
                            </template>
                        </td>
                        <td class="nowrap">
                            <a class="btn btn-info" @click="transition(v.url)">
                                <i class="fas fa-download"></i>ダウンロード
                            </a>
                        </td>
                        <td v-show="is_root">
                            <a class="btn btn-warning" @click="toggle(v)" v-if="!v.deleted_at">
                                <i class="fas fa-trash-alt"></i>
                            </a>
                            <a class="btn btn-outline-success" @click="toggle(v)" v-else>
                                <i class="fas fa-redo-alt"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <documents-editor
                id="editor"
                :order_id="order_id"
                @save="reload()"
                ref="documentsEditor">
            </documents-editor>
            <categories
                id="categories"
                ref="categories">
            </categories>

            <articles
                id="articles"
                ref="articles">
            </articles>
            <article-Brands
                id="articleBrands"
                ref="articleBrands">
            </article-Brands>
        </div>
    </div>
</template>

<style lang="scss">
    h2 {
        padding: .2em;
        margin: .5em 0;
        border-bottom: 1px solid #e5e5e5;
    }
    .search-border {
        padding-left: 25%;
        padding-right: 25%;
    }
    .document {
        padding: 10px;
    }
</style>

<script>
    import documentsEditor from './documentsEditor.vue'
    import articles from '../../master/articles/articles.vue'
    import articleBrands from '../../master/articleBrands/articleBrands.vue'
    import categories from '../../master/categories/categories.vue'

    export default {

        components: {
            documentsEditor,
            articles,
            articleBrands,
            categories,
        },
        data () {
            return {
                order_id: null,
                documents: [],
                articles: [],
                article_brands:[],
                categories: [],
                article_searches: [],
                brand_searches: [],
                category_searches: [],
                editor: {},
                is_root: this.$store.state.is_root,
            }
        },
        methods: {
            reload() {
                this.isLoading = true;
                this.editor.article_searches = this.article_searches;
                this.editor.category_searches = this.category_searches;
                axios.get("/tools/documents/lists", {params: this.editor}).then(res => {
                    this.documents = res.data;
                }).catch(err => {
                    Alert.err(err.response);
                }).finally(() => this.isLoading = false);
            },
            edit(id = null) {
                this.order_id = id;
                setTimeout(() => $("#editor").modal());
            },
            categoryEdit() {
                setTimeout(() => $("#categories").modal());
            },
            articleEdit() {
                setTimeout(() => $("#articles").modal());
            },
            articleBrandsEdit() {
                setTimeout(() => $("#articleBrands").modal());
            },
            brandCheck(article_brand_id) {
                // 対象のブランドのidの商品を配列に追加・削除する
                if (this.brand_searches.indexOf(article_brand_id) == -1) {
                    for (let i = 0; i < this.articles.length; i++) {
                         if (this.article_searches.indexOf(this.articles[i].id) != -1 && article_brand_id == this.articles[i].article_brand_id) {
                            let idx = this.article_searches.indexOf(this.articles[i].id);
                            this.article_searches.splice(idx, 1);
                        }
                    }
                } else {
                     for (let i = 0; i < this.articles.length; i++) {
                         if (this.article_searches.indexOf(this.articles[i].id) == -1 && article_brand_id == this.articles[i].article_brand_id) {
                            this.article_searches.push(this.articles[i].id);
                        }
                    }
                }
                this.reload();
            },

            // 削除・復帰
            toggle(v) {
                // 初期値に復帰情報を設定
                let action = "復帰";
                let type = "info";
                let mode = false;
                // 削除の場合、削除情報に変更して表示
                if (!v.deleted_at) {
                    action = "削除";
                    type   = "warning";
                    mode = true
                }
                swal ({
                    title: `ドキュメントマスタ${action}`,
                    text: `指定のレコードを${action}します。\nよろしいですか？`,
                    icon: type,
                    buttons: ["キャンセル" , action],
                    dangerMode: mode,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        axios.post("/tools/documents/toggle", {id: v.id}).then(res => {
                            this.reload();
                            Alert.msg(res.data);
                        }).catch(err => {
                            Alert.err(err.response);
                        });
                    }
                });
            },
            reset() {
                this.brand_searches = [];
                this.article_searches = [];
                this.category_searches = [];
                this.reload();
            },
            transition(url) {
                window.open(url);
            },
        },
        mounted() {
            this.reload();
            // 各マスタの読み込み
            axios.get("/tools/documentsMaster/master_lists").then(res => {
                this.articles = res.data["articles"];
                this.article_brands = res.data["article_brands"];
                this.categories = res.data["categories"];
            }).catch(err => {
                Alert.err(err.response);
            }).finally(() => this.isLoading = false);
        },
    }
</script>
