import { createStore } from 'vuex'

export default createStore({
    state: {
        // 初期データ
        user_id: null,
        is_root: null,
        is_debug: null,
        app_name: null,
    },
    mutations : {
        reloadBrand(state, brand) {state.brand = brand;},
        setUserId(state, id) {state.user_id = id;},
        setIsRoot(state, is_root) {state.is_root = is_root;},
        setIsDebug(state, is_debug) {state.is_debug = is_debug;},
        setAppName(state, app_name) {state.app_name = app_name}
    },
    actions: {
        async reloadBrand(context, brand_code) {
            context.commit("reloadBrand", brand_code);
        }
    },
    getters: {
        userId(state) { return state.user_id; },
        isRoot(state) { return state.isRoot; },
        isDebug(state) { return state.isDebug; },
        appName(state) {return state.app_name;},
    }
});
