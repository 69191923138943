
export default {
    err: (m) => {
        if (typeof m == "object") {
            console.error(m);
            if (m.response) m = m.response;
            // 認証エラーの場合はログアウトさせる
            if (m.status == 401 || m.status == 419) {
                location.href = "/";
                return;
            }
            // エラーメッセージバッグはまとめる
            let message = m.data.message || m.data || m.statusText;
            if (m.data.errors instanceof Array || m.data.errors instanceof Object) {
                let errors = [];
                for (let key in m.data.errors) {
                    errors.push(m.data.errors[key]);
                }
                message = errors.join("<br>");
            } else if (m.data.errors) {
                message = m.data.errors;
            } else {
                message = m.data;
            }
            m = `${m.status}: ${message}`;
        }
        $.notify({
            icon: 'fa-solid fa-circle-xmark',
            message: m
        }, {
            type: "danger",
            mouse_over: "pause",
            z_index: 1051
        });
    },
    msg: (m) => {
        console.info(m);
        $.notify({
            icon: 'fa-solid fa-circle-check',
            message: m
        }, {
            type: "info",
            mouse_over: "pause",
            z_index: 1051
        });
    },
    warn: (m) => {
        $.notify({
            icon: 'fa-solid fa-circle-exclamation',
            message: m
        }, {
            type: "warning",
            mouse_over: "pause",
            z_index: 1051
        });
    }
}