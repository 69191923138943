<template>
    <div>
        <legend style="margin-bottom:0px;">返金</legend>



        <hr style="margin-top:0px;">
        <div class="container">
            <ul class="nav nav-tabs refund-tabs">
                <li class="nav-item" role="presentation" id="tab-1" >
                    <a class="nav-link" v-bind:class="{ active: tab_code == 'precs' }" href="#" @click="changeTab('precs')">公式</a>
                </li>
                <li class="nav-item" role="presentation" id="tab-2">
                    <a class="nav-link" v-bind:class="{ active: tab_code == 'rakuten' }" href="#" @click="changeTab('rakuten')">楽天</a>
                </li>
                <li class="nav-item" role="presentation" id="tab-3">
                    <a class="nav-link" v-bind:class="{ active: tab_code == 'mall' }" href="#" @click="changeTab('mall')">PayPayモール(元Yahoo!)/au PAY マーケット(元au Wowma!)/LOHACO</a>
                </li>
            </ul>

            <div class="card" v-if="tab_code == 'precs'" style="">
                <div class="card-block row form-inline">
                    <div class="col-md-4 offset-md-2">
                        商品代金
                        <input
                            type="number"
                            id="price01"
                            class="form-control text-right "
                            v-model="product"
                            min="0"
                            autocomplete="off">
                        円
                    </div>
                    <div class="col-md-4">
                        クーポン使用
                        <input type="number" id="coupon01" class="form-control text-right " max="100" min="0" autocomplete="off" v-model="coupon">
                        %
                    </div>
                </div>
                <hr class="horizon">
                <div class="card-block row form-inline">
                    <div class="col-md-4 offset-md-4">
                        通常返金額
                        <input type="number" id="result01" class="form-control text-right result" v-model=refund_total readonly>円
                    </div>
                </div>
            </div>

            <div class="card" v-if="tab_code == 'rakuten'" style="">
                <div class="card-block row form-inline">
                    <div class="col-md-4 offset-md-2">
                        商品代金
                        <input
                            type="number"
                            id="price01"
                            class="form-control text-right "
                            v-model="product"
                            min="0"
                            autocomplete="off">
                        円
                    </div>
                    <div class="col-md-4">
                        クーポン使用
                        <input type="number" id="coupon01" class="form-control text-right " max="100" min="0" autocomplete="off" v-model="coupon">
                        円
                    </div>
                </div>

                <div class="card-block row form-inline">
                    <div class="col-md-12 text-center">
                        ポイント倍率
                        <input type="number" id="mgnf02" max="99" min="0" autocomplete="off" maxlength="2" class="form-control text-right" v-model="rakuten_point">
                        %
                    </div>
                </div>

                <hr class="horizon">

                <div class="card-block row form-inline">
                    <div class="col-md-4 offset-md-2">
                        <input type="text" id="result02p" class="form-control text-right result" v-model=point readonly>
                        ポイント
                    </div>
                    <div class="col-md-4">
                        楽天返金額
                        <input type="text" id="result02" class="form-control text-right result" v-model=refund_rakuten_total readonly>
                        円
                    </div>
                </div>

            </div>

            <div class="card" v-if="tab_code == 'mall'" style="">
                <div class="card-block row form-inline">
                    <div class="col-md-4 offset-md-2">
                        商品代金
                        <input
                            type="number"
                            id="price01"
                            class="form-control text-right "
                            min="0"
                            autocomplete="off"
                            value="4980"
                            onchange="henkinKeisan()">
                        円
                    </div>
                    <div class="col-md-4">
                        クーポン使用
                        <input type="number" id="coupon01" class="form-control text-right " max="100" min="0" autocomplete="off" v-model="coupon">
                        円
                    </div>
                </div>

                <hr class="horizon">

                <div class="card-block row form-inline">
                    <div class="col-md-8 offset-md-2">
                        PayPayモール(元Yahoo!)/au PAY マーケット(元au Wowma!)/LOHACO 返金額
                        <input type="text" id="result03" class="form-control  text-right result" v-model=refund_mall_total readonly>
                        円
                    </div>
                </div>
            </div>
        </div>

        <legend style="margin-bottom:0px;">キャンセル料計算</legend>
        <hr style="margin-top:0px;">

        <div class="container-fluid">
            <div class="row margined form-inline">
                <div class="col-md-3">
                    商品A
                    <input type="number" id="prdA" v-model.number="product_a" class="form-control text-right " min="0" autocomplete="off">
                    円
                </div>
                <div class="col-md-3">
                    +
                    商品B
                    <input type="number" id="prdB" v-model.number="product_b" class="form-control text-right " min="0" autocomplete="off">
                    円
                </div>
                <div class="col-md-3">
                    +
                    商品C
                    <input type="number" id="prdC" v-model.number="product_c" class="form-control text-right " min="0" autocomplete="off">
                    円
                </div>
                <div class="col-md-3">
                    =
                    <input type="text" id="prdResult" class="form-control text-right result" v-model=product_sum readonly>
                    円
                </div>
            </div>
            <div class="row margine ">
                <div class="col-md-3">
                    商品の10%<br />
                    <div class="btn-group">
                        <input type="number" class="form-control text-right result"  v-model=product_10 readonly>円
                    </div>
                </div>
                <div class="col-md-3">
                    事務手数料<br />
                    <div class="btn-group">
                        <input type="number" id="prdShpngFee"
                            class="form-control text-right result dropdown-toggle"
                            v-model="admin_fee"
                            data-toggle="dropdown">
                        <ul class="dropdown-menu">
                            <li><div class="dropdown-item" @click="change_admin(0)">0円</div></li>
                            <li><div class="dropdown-item" @click="change_admin(500)">500円</div></li>
                        </ul>
                    </div>
                    円
                </div>
                <div class="col-md-3">
                    往復送料<br />
                    <div class="btn-group">
                        <input type="number" id="prdRoundTripFee"
                            class="form-control text-right result dropdown-toggle"
                            v-model="round_shipping_fee"
                            data-toggle="dropdown">
                        <ul class="dropdown-menu" >
                            <li><div class="dropdown-item" @click="change_round_shipping(0)">0円</div></li>
                            <li><div class="dropdown-item" @click="change_round_shipping(500)">500円</div></li>
                            <li><div class="dropdown-item" @click="change_round_shipping(660)">660円</div></li>
                            <li><div class="dropdown-item" @click="change_round_shipping(1000)">1,000円</div></li>
                            <li><div class="dropdown-item" @click="change_round_shipping(1320)">1.320円</div></li>
                        </ul>
                    </div>
                    円
                </div>
                <div class="col-md-3">
                    往復離島手数料<br />
                    <div class="btn-group">
                        <input type="number" id="prdIslandFee"
                            class="form-control text-right result dropdown-toggle"
                            v-model="round_island_fee"
                            data-toggle="dropdown">
                        <ul class="dropdown-menu" >
                            <li><div class="dropdown-item" @click="change_round_island(0)">0円</div></li>
                            <li><div class="dropdown-item" @click="change_round_island(2730)">2,730円</div></li>
                        </ul>
                    </div>
                    円
                </div>
            </div>
            <div class="row margined">
                <div class="col-md-4">
                    合計キャンセル料<br />
                    <div class="btn-group">
                        <input type="text" id="result04" class="form-control text-right"  v-model=cancel_sum readonly>円
                    </div>
                </div>
            </div>
        </div>
        <p class="note4">縛り期間中のキャンセル料は計算表と異なる金額です。ご注意ください。</p>
    </div>
</template>

<style>

    .card-block {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .card-block .border-bottom {
        padding-top: 5px;
    }

    .card-footer {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    hr.horizon {
        margin-left: 5%;
        margin-right: 5%;
    }

    .form-control:focus {
        border-color: #20adc1;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #21dff3;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #21dff3;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }


    .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
        border-color: #555;
        border-bottom-color: transparent;
    }

    .result {
        border: 0;
        border-bottom: 1px solid #555;
        border-radius: 0;
        box-shadow: none;
    }

    .margined > div {
        margin: 1em 0;
    }

</style>

<script>
    import routing from 'mixins/routing';
    import vSelect from "vue-select";
    import 'vue-select/dist/vue-select.css';
    import BigNumber from "bignumber.js";

    export default {
        mixins: [routing],
        components: {
            vSelect,
        },
        data: () => ({
            tab_code: "precs",
            product: 4980,
            coupon: 0,
            rakuten_point: 0,
            product_a: 4980,
            product_b: 0,
            product_c: 0,
            admin_fee: 0,
            round_island_fee:0,
            round_shipping_fee:0,
        }),
        methods: {
            changeTab(tab_code) {
                this.tab_code = tab_code;
            },
            change_round_island(fee) {
                this.round_island_fee = fee;
            },
            change_round_shipping(fee) {
                this.round_shipping_fee = fee;
            },
            change_admin(fee) {
                this.admin_fee = fee;
            }, 
            calc_point() {
                if (this.coupon > 0) {
                    let no_coupon_point = Math.floor(BigNumber(this.product).div(1.1) / 100) * this.rakuten_point;
                    let after_discount = this.coupon / (BigNumber(this.product).div(1.1));
                    return Math.floor(no_coupon_point - (no_coupon_point * after_discount));
                } else {
                    return (Math.floor(Math.floor(BigNumber(this.product).div(1.1)) / 100) * this.rakuten_point);

                }
            },
        },
        computed: {
            // 返金計算処理
            refund_total() {
                return this.product - Math.floor(this.product * (this.coupon / 100));
            },
            refund_rakuten_total() {
                return this.product - this.calc_point() - this.coupon;
            },
            refund_mall_total() {
                return this.product - this.coupon;
            },
            point() {
                return this.calc_point();
            },
            product_sum() {
                return this.product_a + this.product_b + this.product_c;
            },
            product_10() {
                return (this.product_a + this.product_b + this.product_c) / 10;
            },
            cancel_sum() {
                return ((this.product_a + this.product_b + this.product_c) / 10 + this.admin_fee + this.round_island_fee + this.round_shipping_fee);
            }
        },
        mounted() {

        }

    }
</script>