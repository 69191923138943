<template>
    <div>
        <legend>タスク</legend>
        <div class="container-fluid" >
            <div class="d-flex flex-row pb-3">
                <div class="col btn-group btn-group-toggle data-container='body'" data-toggle="buttons">
                    <template v-for="(v, i) in task_menus">
                        <label class="btn btn-outline-dark" :class="{active: params.menu_id == v.id}">
                            <input type="radio" v-model="params.menu_id"  v-bind:value="v.id" @change="change_menu(v.id)">
                            {{v.menu_name}}
                        </label>
                    </template>
                </div>
            </div>

            <div class="row pt-2">
                <div v-for="(v, i) in tasks"
                    v-bind:class="{ deleted: v.deleted_at }" class="col-md-3 my-1" style="display: inline-block;" :key="i">
                    <div class="card h-100 border-secondary hover">
                        <div class="head-hover text-left border-bottom bg-secondary" style="color:white;"
                            v-clipboard="v.body"
                            @click="onCopy(v.title)">
                            {{v.title}}
                            <i class="fas fa-copy"></i>
                        </div>
                        <div class="card-body flex-wrap overflow-auto p-2">
                            <p>{{v.body}}</p>
                        </div>
                        <div class="card-footer p-2" v-if="v.footer">
                            <div>
                                <div class="border-light">{{v.footer}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .card-footer{
        font-size: 0.8rem;
    }
    .card-body {
        font-size: 1rem;
        height: 130px;
    }

    ::-webkit-scrollbar {
    width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #555;
    }
</style>

<script>
    import { defineComponent, ref } from 'vue'
    import { toClipboard } from '@soerenmartius/vue3-clipboard'
    import routing from 'mixins/routing';
    export default defineComponent ({
        setup() {
            const value = ref('lorem')
            return { value, toClipboard }
        },
        mixins: [routing],
        data () {
            return {
                params: {
                    menu_id: this.$route.query.menu_id ? this.$route.query.menu_id : null,
                    page: this.$route.query.page ? this.$route.query.page : 1,
                },
                order_id: null,
                isLoading: false,
                tasks: [],
                task_menus: [],
            }
        },
        methods: {
            reload() {
                this.isLoading = true;
                axios.get("/tools/tasks/lists", {params: this.params}).then(res => {
                    this.tasks = res.data;
                    this.routing();
                }).catch(err => {
                    Alert.err(err.response);
                }).finally(() => this.isLoading = false);
            },
            menu_reload() {
                axios.get("/tools/tasks/menus", {params: this.params}).then(res => {
                    this.task_menus = res.data.card_menus;
                    this.params.menu_id = this.task_menus[0]["id"];
                    this.routing();
                }).catch(err => {
                    Alert.err(err.response);
                }).finally(() => this.isLoading = false);
            },
            edit(id = null) {
                this.order_id = id;
                setTimeout(() => $("#editor").modal());
            },
            change_menu(menu_id) {
                this.params.menu_id = menu_id;
                this.routing();
            },
            onCopy(title) {
                Alert.msg("【" + title + "】をコピーしました");
            },
        },
        mounted() {
            this.menu_reload();
            this.reload();
        },
    })
</script>