<template>
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" id="editor">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span class="glyphicon glyphicon-pencil"></span>
                        <span v-if="editor.id">ユーザ編集</span>
                        <span v-else>新規ユーザ</span>
                    </h4>
                    <button type="button" class="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <input type="hidden" name="id" v-model="editor.id">
                            <div class="form-group">
                                <label>メールアドレス（ログイン用）</label>
                                <input type="text" v-model="editor.email" class="form-control" placeholder="メールアドレス" @keydown.ctrl.enter="save">
                            </div>
                            <div class="form-group">
                                <label>ADアカウント（ログイン用）</label>
                                <input type="text" v-model="editor.account" class="form-control" placeholder="ADアカウント" @keydown.ctrl.enter="save">
                            </div>
                            <div class="form-group">
                                <label>Gmail（外部ログイン用）</label>
                                <input type="text" v-model="editor.gmail" class="form-control" placeholder="Gmail" @keydown.ctrl.enter="save">
                            </div>
                            <div class="form-group">
                                <label>氏名</label>
                                <input type="text" v-model="editor.name" class="form-control" placeholder="氏名" @keydown.ctrl.enter="save">
                            </div>
                            <div class="form-group">
                                <label>パスワード（変更時のみ）</label>
                                <input type="password" v-model="editor.pass_1" class="form-control" placeholder="パスワード" @keydown.ctrl.enter="save">
                            </div>
                            <div class="form-group">
                                <label>パスワード確認（変更時のみ）</label>
                                <input type="password" v-model="editor.pass_2" class="form-control" placeholder="パスワード確認" @keydown.ctrl.enter="save">
                            </div>
                            <div class="form-group">
                                <label>特権</label><br />
                                <label class="btn btn-danger" @keydown.ctrl.enter="save">
                                    <input type="checkbox" v-model="editor.is_root" true-value="1" false-value="0">
                                    管理者
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">閉じる</button>
                    <button type="button" class="btn btn-primary" @click="save()">保存(Ctrl+Enter)</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .menus {
        padding: .3em;
        margin-bottom: .3em;
    }
    img.icon {
        width: 24px;
        height: 24px;
    }
    .label-path {
        margin: .1em;
    }
</style>


<script>
    export default {
        props: {
            id: {
                required: true
            },
            user_id: {},
        },
        data() {
            return {
                editor: {},
                sections: [],
                roles: [],
                app: "seven",
            }
        },
        methods: {
            // 保存
            save() {
                axios.post("/master/users/save", this.editor).then(res => {
                    Alert.msg(res.data);
                    $(`#${this.id}`).modal("hide");
                    this.$emit('save');
                }).catch(err => {
                    Alert.err(err.response);
                });
            },
            reload() {
                // 編集の場合、該当データを取得
                if (this.user_id) {
                    axios.get("/master/users/edit", { params: {id: this.user_id}}).then(res => {
                        res.data.token = null;
                        this.editor = res.data;
                    }).catch(err => {
                        Alert.err(err.response);
                        $("#editor").modal("hide");
                        this.$emit('save');
                    });
                // 新規追加の場合、項目を空にする
                } else {
                    this.editor = {};
                }
            },
        },
        mounted() {
            // 編集モーダルが開かれた場合
            $(`#${this.id}`).on("show.bs.modal", (e) => this.reload());

            axios.get("/master/sections/lists").then(res => {
                this.sections = res.data;
            }).catch(err => {
                Alert.err(err.response);
            });
            axios.get("/master/roles/lists").then(res => {
                this.roles = res.data;
            }).catch(err => {
                Alert.err(err.response);
            });
        }

    }
</script>