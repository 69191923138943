<template>
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" :id="id">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>商品</span>
                    </h4>
                </div>
                <div class="modal-body">
                    <!-- 新規追加ボタン -->
                    <div class="text-right">
                        <button type="button" class="btn btn-outline-info mb-3" @click="edit()">新規作成</button>
                    </div>

                    <div class="container">
                        <transition mode="out-in">
                            <table class="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th style="width:4em;">編集</th>
                                        <th>ID</th>
                                        <th>商品名</th>
                                        <th>商品ブランド</th>
                                        <th style="width:4em;">削除</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="v in articles" :class="{'deleted': v.deleted_at}">
                                        <td>
                                            <a class="btn btn-outline-secondary" @click="edit(v.id)">
                                                <span class="fas fa-edit"></span>
                                            </a>
                                        </td>
                                        <td>
                                            {{v.id}}
                                        </td>
                                        <td>
                                            {{v.article_name}}
                                        </td>
                                        <td>
                                            <div v-if="v.article_brand">
                                                {{v.article_brand.brand_name}}
                                            </div>
                                        </td>
                                        <td>
                                            <a class="btn btn-warning" @click="toggle(v)" v-if="!v.deleted_at">
                                                <i class="fas fa-trash-alt"></i>
                                            </a>
                                            <a class="btn btn-outline-success" @click="toggle(v)" v-else>
                                                <i class="fas fa-redo-alt"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
        <articles-editor
            id="articlesEditor"
            :order_id="order_id"
            @save="reload()"
            ref="articlesEditor">
            </articles-editor>
    </div>
</template>

<script>
    import articlesEditor from './articlesEditor.vue';

    export default {
        components: {
            articlesEditor,
        },
        props: {
            id: {
                default: "articles",
            },
        },
        data() {
            return {
                articles: [],
                order_id : null,
            }
        },
        methods: {
            reload() {
                axios.get("/master/articles/lists").then(res => {
                        this.articles = res.data;
                    }).catch(err => {
                        Alert.err(err.response);
                    });
            },
            edit(id = null) {
                this.order_id = id;
                setTimeout(() => $("#articlesEditor").modal());
            },
            toggle(v) {
                // 初期値に復帰情報を設定
                let action = "復帰";
                let type = "info";
                let mode = false;
                // 削除の場合、削除情報に変更して表示
                if (!v.deleted_at) {
                    action = "削除";
                    type   = "warning";
                    mode = true
                }
                swal ({
                    title: `商品マスタ${action}`,
                    text: `指定のレコードを${action}します。\nよろしいですか？`,
                    icon: type,
                    buttons: ["キャンセル" , action],
                    dangerMode: mode,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        axios.post("/master/articles/toggle", {id: v.id}).then(res => {
                            this.reload();
                            Alert.msg(res.data);
                        }).catch(err => {
                            Alert.err(err.response);
                        });
                    }
                });
            },
        },
        mounted() {
            // 編集モーダルが開かれた場合
            $(`#${this.id}`).on("show.bs.modal", (e) => {
                this.reload();
            });
        }
    }
</script>