import { createRouter, createWebHistory } from 'vue-router'

// import Vue from 'vue';
// import VueRouter from 'vue-router';
// Vue.use(VueRouter);



export default createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: require('views/index.vue').default },
        { path: '/403', component: require('views/403.vue').default },
        { path: '/diYet54BavXBtfvPrDWU', component: require('views/master/users/users.vue').default },
        { path: '/cxSUhVyKHz2WxFJfYhd7', component: require('views/master/movies/movies.vue').default },
        { path: '/aZwZW2Jj3TQZrSaMPLUt', component: require('views/master/taskMenus/taskMenus.vue').default },
        { path: '/NmcsvftpuLVr2YKmmJJd', component: require('views/master/shopMenus/shopMenus.vue').default },
        { path: '/yEs42jMwpaG3DbjC3Bpe', component: require('views/tools/tasks/tasks.vue').default },
        { path: '/D8mYGsv2KJnyTp75GHcJ', component: require('views/tools/tasks/tasksMaster.vue').default },
        { path: '/ZEEiCyVNwsZSTmZjtGfM', component: require('views/tools/shopMemos/shopMemos.vue').default },
        { path: '/amcsvftpuLVr2YKmmJJd', component: require('views/tools/shopMemos/shopMemosMaster.vue').default },
        { path: '/GBhMjnPGW4aCPThkgDaw', component: require('views/tools/refunds/refunds.vue').default },
        // { path: '/QFR6q8NiTfyvjbkQaVMP', component: require('views/tools/documents/documents.vue').default },
        { path: '/:any*', component: require('views/404.vue').default },
    ]
});
