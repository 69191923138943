<template>
	<div>
		<div class="container-fluid picking-list" v-cloak>
			<legend>動画一覧</legend>


			<!-- 新規追加ボタン -->
			<div class="text-right">
				<button type="button" class="btn btn-outline-info" @click="append()">新規作成</button>
			</div>

			<div class="container">
				<!-- 動画テーブル -->
				<transition mode="out-in">
				<table class="table table-striped table-hover">
					<thead>
						<tr>
							<th style="width:4em;">編集</th>
							<th>動画ID</th>
							<th>開始秒</th>
							<th>追加日</th>
							<th style="width:4em;">削除</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="v in movies" :class="{'deleted': v.deleted_at}">
							<td>
								<a class="btn btn-secondary" v-on:click="edit(v.id)">
									<span class="fas fa-edit"></span>
								</a>
							</td>
							<td>
								<a v-bind:href="'https://www.youtube.com/watch?v=' + v.movie_id" target="_blank">
									{{v.movie_id}}
									<i class="fa fa-youtube"></i>
								</a>
							</td>
							<td>
								{{v.start_second}}秒
							</td>
							<td>
								{{v.created_at}}
							</td>
							<td>
								<a class="btn btn-warning" @click="toggle(v)" v-if="!v.deleted_at">
									<i class="fas fa-trash-alt"></i>
								</a>
								<a class="btn btn-outline-success" @click="toggle(v)" v-else>
									<i class="fas fa-redo-alt"></i>
								</a>
							</td>
						</tr>
					</tbody>
				</table>
				</transition>
			</div>
			<!-- 動画マスタモーダル -->
			<movies-editor
				id="editor"
				:order_id="order_id"
				@save="reload()"></movies-editor>
		</div>
	</div>
</template>

<script>
	import moviesEditor from './moviesEditor.vue';

	export default {
		components: {
			moviesEditor,
		},
		data: () => ({
			order_id: null,
			isloading: false,
			movies: [],
		}),

		methods: {
			reload() {
				this.isLoading = true;
				axios.get("/master/movies/lists").then(res => {
					this.movies = res.data;
				}).catch(err => {
					Alert.err(err.response);
				}).finally(() => this.isLoading = false);
			},
			// 新規追加モーダル
			append() {
				this.edit(null);
			},
			// 編集モーダル
			edit(id) {
				this.order_id = id;
				setTimeout(() => $("#editor").modal());
			},
			// 削除・復帰
			toggle(v) {
				// 初期値に復帰情報を設定
				let action = "復帰";
				let type = "info";
				let mode = false;
				// 削除の場合、削除情報に変更して表示
				if (!v.is_locked) {
					action = "削除";
					type   = "error";
					mode = true
				}
				swal ({
					title: `動画マスタ${action}`,
					text: `指定の動画を${action}します。\nよろしいですか？`,
					icon: type,
					buttons: ["キャンセル" , action],
					dangerMode: mode,
				})
				.then((willDelete) => {
					if (willDelete) {
						axios.post("/master/movies/toggle", {id: v.id}).then(res => {
							this.reload();
							setTimeout(() => Alert.msg(res.data));
						}).catch(err => {
							Alert.err(err.response);
						});
					}
				});
			},
		},
		mounted() {
			this.reload();
		}
	}
</script>