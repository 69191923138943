<template>
    <div>
        <div class="container-fluid picking-list" v-cloak>
            <legend>ショップメニューマスタ</legend>


            <!-- 新規追加ボタン -->
            <div class="text-right">
                <button type="button" class="btn btn-outline-info mb-3" @click="edit()">新規作成</button>
            </div>

            <div class="container">
                <transition mode="out-in">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th style="width:4em;">編集</th>
                            <th>メニュー名</th>
                            <th style="width:4em;">削除</th>
                        </tr>
                    </thead>
                    <draggable @end="onEnd($event)" tag="tbody" item-key="no" :list="shop_menus">
                        <template #item="{ element }">
                            <tr :class="{'deleted': element.deleted_at}" style="width:800px">
                                <td>
                                    <a class="btn btn-outline-secondary" @click="edit(element.id)">
                                        <span class="fas fa-edit"></span>
                                    </a>
                                </td>
                                <td class="nowrap">
                                    {{element.menu_name}}
                                </td>
                                <td>
                                    <a class="btn btn-warning" @click="toggle(element)" v-if="!element.deleted_at">
                                        <i class="fas fa-trash-alt"></i>
                                    </a>
                                    <a class="btn btn-outline-success" @click="toggle(element)" v-else>
                                        <i class="fas fa-redo-alt"></i>
                                    </a>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </table>
                </transition>
            </div>
            <shop-menus-editor
                id="editor"
                :order_id="order_id"
                @save="reload()"></shop-menus-editor>
        </div>
    </div>
</template>

<script>
    import shopMenusEditor from './shopMenusEditor.vue';
    import draggable from 'vuedraggable';

    export default {
        components: {
            shopMenusEditor,
             draggable,
        },
        data: () => ({
            order_id: null,
            isloading: false,
            shop_menus: [],
        }),

        methods: {
            reload() {
                this.isLoading = true;
                axios.get("/master/shop_menus/lists").then(res => {
                    this.shop_menus = res.data;
                }).catch(err => {
                    Alert.err(err.response);
                }).finally(() => this.isLoading = false);
            },
            edit(id = null) {
                this.order_id = id;
                setTimeout(() => $("#editor").modal());
            },
            toggle(v) {
                // 初期値に復帰情報を設定
                let action = "復帰";
                let type = "info";
                let mode = false;
                // 削除の場合、削除情報に変更して表示
                if (!v.deleted_at) {
                    action = "削除";
                    type   = "warning";
                    mode = true
                }
                swal ({
                    title: `ショップメニューマスタ${action}`,
                    text: `指定のレコードを${action}します。\nよろしいですか？`,
                    icon: type,
                    buttons: ["キャンセル" , action],
                    dangerMode: mode,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        axios.post("/master/shop_menus/toggle", {id: v.id}).then(res => {
                            this.reload();
                            Alert.msg(res.data);
                        }).catch(err => {
                            Alert.err(err.response);
                        });
                    }
                });
            },
            onEnd() {
                // order_number書き換え
                this.shop_menus.map(function(shop, length) {
                    shop.order_number = length + 1;
                    return {shop}
                });
                this.isLoading = true;
                axios.post("/master/shop_menus/dragsort", {shop_menus: this.shop_menus}).then(res => {
                    this.reload();
                }).catch(err => {
                    Alert.err(err.response);
                }).finally(() => this.isLoading = false);
            },
        },
        mounted() {
            this.reload();
        }
    }
</script>