<template>
    <div>
        <legend>{{$store.state.app_name}}トップ</legend>
        <div class="container">
            <div class="row">
                <div class="card col h-80">
                    <img class="card-img-top mt-3" width="100%" src="/img/task.jpg" alt="Card image cap">
                    <hr>
                    <div class="card-body">
                        <h5 class="card-title">
                            タスク
                        </h5>
                        <p>タスク用のテンプレートを使用することが出来ます。<br><br></p>
                        <hr>
                        <div class="text-center mb-1">
                            <a href="/yEs42jMwpaG3DbjC3Bpe" class="btn btn-primary btn-lg button-font" tabindex="-1" role="button" aria-disabled="true">タスク</a>
                        </div>
                        <div v-if="$store.state.is_root == 1" class="text-center mb-1">
                            <a href="/D8mYGsv2KJnyTp75GHcJ" class="btn btn-primary btn-lg button-font" tabindex="-1" role="button" aria-disabled="true">タスクマスタ</a>
                        </div>
                        <div v-if="$store.state.is_root == 1" class="text-center">
                            <a href="/NmcsvftpuLVr2YKmmJJd" class="btn btn-primary btn-lg button-font" tabindex="-1" role="button" aria-disabled="true">タスクメニューマスタ</a>
                        </div>
                    </div>
                </div>
                <div class="card col h-80">
                    <img class="card-img-top mt-3 " width="100%" src="/img/memo.jpg" alt="Card image cap">
                    <hr>
                    <div class="card-body">
                        <h5 class="card-title">
                            ショップメモ
                        </h5>
                        <p>ショップメモ用のテンプレートを使用することが出来ます。</p>
                        <hr>
                        <div class="text-center mb-1">
                            <a href="/ZEEiCyVNwsZSTmZjtGfM" class="btn btn-primary btn-lg button-font" tabindex="-1" role="button" aria-disabled="true">ショップメモ</a>
                        </div>
                        <div v-if="$store.state.is_root == 1" class="text-center mb-1">
                            <a href="/amcsvftpuLVr2YKmmJJd" class="btn btn-primary btn-lg button-font" tabindex="-1" role="button" aria-disabled="true">ショップメモマスタ</a>
                        </div>
                        <div v-if="$store.state.is_root == 1" class="text-center">
                            <a href="/aZwZW2Jj3TQZrSaMPLUt" class="btn btn-primary btn-lg button-font" tabindex="-1" role="button" aria-disabled="true">ショップメニューマスタ</a>
                        </div>
                    </div>
                </div>
                <div class="card col h-80">
                    <img class="card-img-top mt-3" width="100%" src="/img/calclator.jpg" alt="Card image cap">
                    <hr>
                    <div class="card-body">
                        <h5 class="card-title">
                            返金
                        </h5>
                        <p>公式・楽天・その他モールの返金額の計算と、キャンセル料の計算が出来ます。</p>
                        <hr>
                        <div class="text-center">
                            <a href="/GBhMjnPGW4aCPThkgDaw" class="btn btn-primary btn-lg button-font" tabindex="-1" role="button" aria-disabled="true">返金</a>
                        </div>
                    </div>
                </div>
                <div class="card col h-80">
                    <img class="card-img-top mt-3" width="100%"  src="/img/document.jpg" alt="Card image cap">
                    <hr>
                    <div class="card-body">
                        <h5 class="card-title">
                            資料ダウンロード
                        </h5>
                        <p>ファイルとURLを紐付けて、直接ファイルにアクセスすることが出来ます。</p>
                        <hr>
                        <div class="text-center">
                            <a href="/QFR6q8NiTfyvjbkQaVMP" class="btn btn-primary btn-lg button-font" tabindex="-1" role="button" aria-disabled="true">資料ダウンロード</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
    .button-font {
        white-space:nowrap;
        font-size:1rem;
    }
</style>